import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  if (location.pathname === '/') {
    return null;
  }

  const getPersianName = (name) => {
    switch (name) {
      case 'dashboard':
        return 'داشبورد';
      case 'report':
        return 'گزارش';
      case 'beforeafterhistory':
        return 'سوابق تصاویر شما';
      case 'otherfiles':
        return 'فایل های دیگر';
      case 'size':
        return 'گزارش سایز';

      case 'weeklyreport':
        return 'گزارش هفتگی';
      case 'beforeafter':
        return 'تصاویر قبل و بعد';
      case 'weekly-history':
        return 'تاریخچه گزارش‌های هفتگی';
      // Add more cases as needed
      default:
        return name;
    }
  };

  return (
    <div dir="rtl" className="mx-5 my-4 border-b-5 text-sm breadcrumbs">
      <Link to="/">خانه</Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const decodedName = decodeURIComponent(name);
        const persianName = getPersianName(decodedName);

        const isLast = index === pathnames.length - 1;

        return (
          <span key={name}>
            <span> / </span>
            <Link to={routeTo}>
              {isLast ? (
                <span className="font-bold">{persianName}</span>
              ) : (
                persianName
              )}
            </Link>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
