import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import api from '../../../api/api.js';
import FileInputs from '../../../component/FormInputs/FileInputs.js';
import { Alert, Textarea } from 'flowbite-react';
import SubmitButton from '../../../component/submitButton.jsx';
import persianNumbers from '../../../api/persianNumber.js';
import BeforeAfterHistory from './beforeAfterHistory.js';
import Front from '../../../img/front.png';
import Back from '../../../img/back.png';
import Side from '../../../img/side.png';
const BeforAfters = () => {
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState();
  const [status, setStatus] = useState('idle');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  console.log('percent', percent);
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        console.log(parsedCredentials.userId);
        setUserId(parsedCredentials.userId);
      } catch (error) {
        console.log(error);
        setMessage(error.message);
      }
    }
  }, []);

  const handleUploadImages = async (e) => {
    console.log('inside handle upload files');
    const selectedFiles = Array.from(e.target.files); // Convert FileList to array
    setFiles(selectedFiles); // Update state with the selected files
    await uploadFiles(selectedFiles); // Automatically upload images after selection
  };

  const uploadFiles = async (selectedFiles) => {
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append('images', file); // 'images' should match the key the backend expects
    });

    try {
      setStatus('uploading');
      console.log('upload files');
      const response = await api.post(
        '/imageUploader/multiple', // Update with your Express server endpoint
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total);
            setPercent(percentage);
          },
        }
      );
      setImageUrls(response.data.urls); // Assuming response.data.urls is an array of image URLs
      setButtonDisabled(false);
      setPercent(100);
      setStatus('images_uploaded');
    } catch (error) {
      console.log(error.response.data);
      setMessage(error.response.data);
      console.error('Error uploading files', error);
      setStatus('error');
      Bugsnag.notify('Error uploading images', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus('loading');

    try {
      const response = await api.post('/report/imagereport', {
        userId: userId,
        images: imageUrls,
      });
      setStatus('success');
      setMessage('تصاویر شما با موفقیت ثبت شد');
      alert('تصاویر شما با موفقیت ثبت شد');
      window.location.href = '/dashboard/beforeafterhistory';
    } catch (error) {
      console.error(error);
      setMessage('خطا در ارسال گزارش');
      setStatus('error');
      Bugsnag.notify('Error in submitting report');
    }
  };

  const samples = [
    {
      id: 1,
      name: 'عکس از روبرو',
      value: 'Front',
      img: Front,
    },
    {
      id: 2,
      name: 'عکس از پشت',
      value: 'Back',
      img: Back,
    },
    {
      id: 3,
      name: 'عکس از یک سمت',
      value: 'Side',
      img: Side,
    },
  ];

  return (
    <div className="mx-4 font-custom1 flex flex-col mt-5 items-center">
      <div className="grid md:grid-cols-3">
        {samples.map((item, index) => (
          <div key={item.id} className="w-4/5 m-auto md:w-2/3">
            <div className="justify-between rounded-t-lg flex items-center">
              <label className="text-base text-right text-main mx-4 my-2">
                {item.name}
              </label>
            </div>

            <div className="grid md:grid-cols-1 gap-4 rounded-none">
              <div className="flex flex-col items-center justify-center">
                <label className="flex flex-col rounded-lg p-10 group text-center">
                  <div className="text-center">
                    <div className="max-h-48 mt-10">
                      <div className="w-48 h-48 mt-2">
                        <img
                          className="rounded-lg object-contain w-60 h-60"
                          src={item.img}
                          alt={`default ${index}`}
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row  mb-5">
        {status === 'loading' && (
          <div>
            <iframe src="https://lottie.host/embed/95be7968-7651-4619-96f0-1bbe26a2bba7/jkpnuPrKGq.json"></iframe>
          </div>
        )}
        {status === 'error' && (
          <div>
            <Alert color="failure">
              <span className="font-medium">خطا</span>
              <p>{message}</p>
            </Alert>
          </div>
        )}
        {status === 'uploading' && (
          <div>
            <Alert color="info">
              <span className="font-medium">در حال آپلود تصاویر</span>
              <span>%{persianNumbers(percent)}</span>
            </Alert>
          </div>
        )}
        {status === 'images_uploaded' && (
          <div>
            <Alert color="success">
              <span className="font-medium">تصاویر شما اماده ی آپلود شدند</span>
              <p>میتوانید با زدن دکمه ثبت، تصاویر خود را ارسال کنید</p>
            </Alert>
          </div>
        )}
      </div>
      <form
        className="rounded-lg items-center w-full max-w-2xl"
        onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-1 gap-5 items-center justify-items-center">
          <div className="w-full flex flex-col items-center">
            <FileInputs
              onChange={handleUploadImages}
              id="images"
              name="images"
              helpText="تمامی تصاویر و فایلهای مورد نظر خود را انتخاب کنید.


              "
              multiple={true} // Allow multiple file uploads
            />
          </div>
        </div>

        <>
          {status === 'loading' && (
            <div className="weekly-report__progress">
              <p className="text-main">در حال ذخیره...</p>
              <progress
                className="weekly-report__progress-bar"
                value={percent}
                max="100"
              />
            </div>
          )}

          {!buttonDisabled && (
            <div className="flex justify-center">
              <SubmitButton
                handleSubmit={handleSubmit}
                status={status}
                disabled={status === 'loading'}
              />
            </div>
          )}
        </>
      </form>
      <div className="mt-10">
        <h1 className="text-2xl font-bold">تصاویر قبلی</h1>
      </div>
      <BeforeAfterHistory />
    </div>
  );
};

export default BeforAfters;
