import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import UserIndex from '../userProfile/userIndex';
import api from '../../api/api';

import { handleGetCoachreports } from '../../api/getData';
import UserContext from '../../context/usercontext';

import { Button } from 'flowbite-react';
function Dashboard() {
  const [userId, setUserId] = useState(null);
  const [userProducts, setUserProducts] = useState([]);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserCredentials = async () => {
    const credentials = localStorage.getItem('credentials');
    const parsedCredentials = JSON.parse(credentials);
    if (parsedCredentials) {
      setUserId(parsedCredentials.userId);
      // Optionally set userProducts if needed here
    }
  };

  const getUserDetails = async () => {
    try {
      await getUserCredentials();
    } catch (error) {
      console.log(error);
      setUserLevel(null);
    }
  };

  const getUserProducts = async () => {
    setStatus('loading');
    if (userId === null) return;

    try {
      const response = await api.get(`/products/${userId}`);
      console.log('response in get user', response.data);
      const data = response.data;
      setUserProducts(data);
      console.log(JSON.stringify(userProducts));
      localStorage.setItem('userProduct', JSON.stringify(data));

      getCoachReports();
      setStatus('success');
    } catch (error) {
      console.log(error);
      setStatus('error');
    }
  };

  useEffect(() => {
    if (userId !== null) {
      getUserProducts();
    }
  }, [userId]);

  const [userLevel, setUserLevel] = useState();
  const [hasReport, setHasReport] = useState(false);
  const [coachReport, setCoachReport] = useState();

  const getCoachReports = async () => {
    if (userId === null) return;

    try {
      const data = await handleGetCoachreports(userId);
      console.log('data', data);
      if (data !== null && data.length > 0) {
        setCoachReport(data[data.length - 1]);
        setHasReport(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoachReports();
  }, [userId]);

  return (
    <div>
      <Helmet>
        <title>پنل کاربری</title>
        <meta name="description" content="پنل کاربری" />
      </Helmet>

      <div className="w-full h-full rounded border-gray-300">
        {status === 'loading' && (
          <div>
            <iframe src="https://lottie.host/embed/95be7968-7651-4619-96f0-1bbe26a2bba7/jkpnuPrKGq.json"></iframe>
          </div>
        )}
        {status === 'success' && (
          <UserIndex
            userProducts={userProducts}
            hasReport={hasReport}
            coachReport={coachReport}
          />
        )}
        {status === 'error' && (
          <div className="flex flex-col items-center justify-center h-full m-5">
            <span>
              خطایی پیش امده است. لطفا ارتباط اینترنت خود را بررسی کنید و در
              صورتی که مشکل حل نشد، مجددا روی لینک ورود به پنل بزنید
            </span>
            <Button
              onClick={() => {
                window.location.href = '/login';
              }}
              className="mt-4 w-1/4 bg-blue-500">
              ورود
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
