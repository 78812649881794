import React from 'react';
import Azar from '../../../img/bg.webp';
import { Statistic } from './stats';
import Testimonial from './testimonial';
import TimeLine from './timeline';
import CtaSection from './cta';
import FirstSection from './fFirstSection';
import { About } from './About';
import { ForWho } from './ForWho';
import Register from './Register';
import TimeLinePng from '../../../img/timeline.png';
import callouts from '../../../data/services';
import RegButton from './button';
import RegCta from './regCTA';
import Prices from './prices';
import { Helmet } from 'react-helmet';

const stickyButtonStyle = {
  position: 'sticky',
  bottom: '0px', // Adjust the value as per your requirement
  height: '200px',
  zIndex: 999, // Adjust the z-index value if needed to ensure it stays above other elements
  marginTop: '0px',
  backgroundColor: 'transparent',
};

function Academy() {
  const ogData = {
    title: 'لاغری اصولی با دوره آکادمی شیپ آپ - آذر شفیعی',
    description:
      'در این دوره یاد میگیرید : بدون ترس از غذاخوردن، به تناسب اندام برسید. اگر رژیم های مختلفی را امتحان کردید و به نتیجه نرسیدسد، شیپ آپ راه حل شماست',
    url: 'https://www.azishafiei.com',
    image: 'https://azishafiei.com/static/media/azi.d0bac2347aeb47da4bbf.jpg',
    siteName: 'آذر شفیعی',
  };
  return (
    <div>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={ogData.title} />
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:site_name" content={ogData.siteName} />
        {/* You can add more Open Graph tags as needed */}

        {/* Other standard meta tags */}
        <meta name="description" content={ogData.description} />
        {/* Other meta tags as needed */}
      </Helmet>
      {/* <FirstSection /> */}
      <div className="bg-white ">
        <Statistic />
      </div>
      {/* <Testimonial /> */}
      {/* <img src={TimeLinePng} alt="timeline" className="w-full" /> */}
      {/* <TimeLine /> */}
      <About />
      <CtaSection />
      <ForWho />
      <Register />

      <div className="bg-transparent" style={stickyButtonStyle}>
        {/* <RegButton data={callouts[0]} /> */}
        <Prices data={callouts[1]} />
      </div>
    </div>
  );
}

export default Academy;
