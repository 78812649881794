import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BeforeAfterHistory from '../report/beforeAfterHistory';

function BeforAfters() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleToggle();
    }
  };

  return (
    <div
      className={`collapse collapse-arrow bg-base-200 ${isOpen ? 'open' : ''}`}>
      <input type="checkbox" checked={isOpen} onChange={handleToggle} />
      <div
        onClick={handleToggle}
        className="collapse-title text-xl font-medium">
        تصاویر پیش و پس از دوره
      </div>
      <BeforeAfterHistory />
    </div>
  );
}

export default BeforAfters;
