import React, { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './App.css';
import Navbars from './component/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import FooterApp from './component/footer';
import PaymentIndex from './pages/payment';
import Download from './pages/download';
import Calculator from './pages/tools';
import Contact from './pages/contact';
import Services from './pages/packages/package';
import Checkout from './pages/checkout';
import PrivacyPolicy from './pages/privacy';
import NotFound from './pages/404';
import PaymentSuccess from './pages/checkout/thanks';
import PaymentUnSuccess from './pages/checkout/cancel';
import SignUp from './pages/signUp/Signup';
import LogIn from './pages/login/LogIn';
import Dashboard from './pages/Dashboard';
import LoadingPayment from './pages/payment/redirection';
import CallbackRial from './pages/checkout/callback';
import ResetPasswordForm from './pages/login/resetPassword';
import ForgotPasswordForm from './pages/login/forgotPasswort';
import ShapeUp from './pages/Dashboard/product/shapeup';
import TermsOfService from './pages/terms';
import ClassSessions from './pages/Dashboard/product/shapeup/';
import BasicForm from './pages/Dashboard/forms/basic';
import NutritionForm from './pages/Dashboard/forms/nutrition';
import ExerciseForm from './pages/Dashboard/forms/ExerciseForm';
import Report from './pages/Dashboard/report';
import WeeklyReport from './pages/Dashboard/report/weeklyReport';
import Bookingform from './pages/booking';
import ReportHistory from './pages/Dashboard/report/history';
import UserContext from './context/usercontext';
import Shapeup from './pages/landing/shapeup/shapeup';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import PayFromApp from './pages/payment/payment';
import ShapeUpPro from './pages/Dashboard/product/shapeupPro';
import ShapeUpTwo from './pages/Dashboard/product/shapeup2';
import SizeReport from './pages/Dashboard/report/sizeReport';
import SizeHistory from './pages/Dashboard/report/sizehisotry';
import WorkoutComponent from './pages/workoutGenerator';

import Team from './pages/team';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from './api/authContxt';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from './api/authContxt';
import Unauthorized from './pages/Dashboard/unauthorized';
import BeforeAfterHistory from './pages/Dashboard/report/beforeAfterHistory';
import DownloadLink from './pages/download';
import DeActive from './pages/Dashboard/deActive';
import ToolsIndex from './pages/Dashboard/tools';
import Tutorials from './pages/Dashboard/product/shapeup/tutorials';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import UserIndex from './pages/userProfile/userIndex';
import ClassSession from './pages/Dashboard/product/shapeup/session';
import Academy from './pages/landing/academy/shapeup';
import PreSignUp from './pages/signUp/preSignUp';
import { SpeedInsights } from '@vercel/speed-insights/react';
import SubscriptionIndex from './pages/subscription';
import UnsubscriptionIndex from './pages/subscription/unsubIndex';
import Private from './pages/landing/private';
import OtherFiles from './pages/Dashboard/report/OtherFiles';
import BeforAfters from './pages/Dashboard/report/BeforAfters';
ReactGA.initialize('G-XV02KW8ZQH');

Bugsnag.start({
  apiKey: '6cdefc33b71811f4487bab1297563586',

  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [userProducts, setUserProducts] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { t, i18n } = useTranslation();
  const [direction, setDirection] = useState('rtl'); // new state variable
  const queryClient = new QueryClient();

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);

      setIsLoggedIn(true);
      setUserId(parsedCredentials.id);
      setUserProducts(parsedCredentials?.userProduct);
      setName(parsedCredentials?.name);
      setEmail(parsedCredentials?.email);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const currentLanguage = i18n.language;
    // If language is a right-to-left language like Arabic
    if (currentLanguage === 'fa') {
      document.documentElement.setAttribute('dir', 'rtl');
      document.documentElement.setAttribute('lang', 'fa');
      setDirection('rtl');
      //chnge the font
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
      document.documentElement.setAttribute('lang', 'en');
      setDirection('ltr');
    }
  }, [i18n.language]);

  const [userDetails, setUserDetails] = useState();

  const ProtectedRoute = () => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    console.log('in protected route', isLoggedIn);
    if (!isLoggedIn) {
      return <LogIn />;
    }

    return <Outlet />;
  };

  // PublicRoute.js
  console.log('userDetails in public route', userDetails);
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <Router>
              <UserContext.Provider value={{ userDetails, setUserDetails }}>
                <div className={direction === 'rtl' ? 'font-custom1' : ''}>
                  <ErrorBoundary>
                    <Navbars className="dark text-foreground bg-background" />

                    <Routes>
                      <Route path="/" element={<Home />} />

                      <Route path="/pricelist" element={<PaymentIndex />} />
                      <Route path="/application" element={<Download />} />
                      <Route path="/calculator" element={<Calculator />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/services" element={<PaymentIndex />} />
                      <Route path="/signup" element={<SignUp />} />
                      <Route path="/userlogin" element={<PreSignUp />} />
                      <Route path="/callback" element={<CallbackRial />} />
                      <Route path="/service/:id" element={<Services />} />
                      <Route path="/login" element={<LogIn />} />
                      <Route path="/team" element={<Team />} />

                      <Route
                        path="/loadingpayment"
                        element={<LoadingPayment />}
                      />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/shapeup" element={<Shapeup />} />
                      <Route path="/academy" element={<Academy />} />
                      <Route path="/private" element={<Private />} />
                      <Route path="*" element={<NotFound />} />
                      <Route path="/download" element={<DownloadLink />} />
                      <Route
                        path="/successful-payment"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/unsuccessful-payment"
                        element={<PaymentUnSuccess />}
                      />
                      <Route
                        path="/reset-password"
                        element={<ResetPasswordForm />}
                      />
                      <Route
                        path="/forgot-password"
                        element={<ForgotPasswordForm />}
                      />
                      <Route
                        path="/terms-of-service"
                        element={<TermsOfService />}
                      />
                      <Route
                        path="/Subscription/:id"
                        element={<SubscriptionIndex />}
                      />
                      <Route
                        path="/unsubscription/:id"
                        element={<UnsubscriptionIndex />}
                      />
                      <Route path="/booking" element={<Bookingform />} />
                      <Route path="/payfromapp" element={<PayFromApp />} />
                      <Route element={<ProtectedRoute />}>
                        <Route
                          path="/dashboard/size-history"
                          element={<SizeHistory />}
                        />
                        <Route
                          path="/dashboard/basicdata"
                          element={<BasicForm />}
                        />
                        <Route
                          path="/dashboard/nutritiondata"
                          element={<NutritionForm />}
                        />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route
                          path="/dashboard/profile"
                          element={<UserIndex />}
                        />
                        <Route path="dashboard/:id" element={<ShapeUp />} />
                        <Route
                          path="dashboard/shapeuppro"
                          element={<ShapeUpPro />}
                        />

                        <Route
                          path="/dashboard/shapeup2"
                          element={<ShapeUpTwo />}
                        />
                        <Route
                          path="/dashboard/deactive"
                          element={<DeActive />}
                        />
                        <Route
                          path="/dashboard/tools"
                          element={<ToolsIndex />}
                        />

                        <Route
                          path="/dashboard/workshop/"
                          element={<ClassSessions />}
                        />
                        <Route
                          path="/dashboard/workshop/:id"
                          element={<ClassSession />}
                        />

                        <Route
                          path="/dashboard/tutorials/:id"
                          element={<Tutorials />}
                        />

                        <Route
                          path="/dashboard/workoutdata"
                          element={<ExerciseForm />}
                        />
                        <Route path="/dashboard/report" element={<Report />} />
                        <Route
                          path="/dashboard/beforeafterhistory"
                          element={<BeforeAfterHistory />}
                        />
                        <Route
                          path="/dashboard/weekly-history"
                          element={<ReportHistory />}
                        />
                        <Route
                          path="/dashboard/report/otherfiles"
                          element={<OtherFiles />}
                        />

                        <Route
                          path="/generateplan"
                          element={<WorkoutComponent />}
                        />

                        <Route
                          path="/dashboard/report/weeklyreport"
                          element={<WeeklyReport />}
                        />
                        <Route
                          path="/dashboard/report/beforeafter"
                          element={<BeforAfters />}
                        />
                        <Route
                          path="/dashboard/report/size"
                          element={<SizeReport />}
                        />
                      </Route>
                    </Routes>

                    <FooterApp
                      isLoggedIn={isLoggedIn}
                      userId={userId}
                      userProducts={userProducts}
                      name={name}
                      email={email}
                    />
                    {/* <Analytics /> */}
                  </ErrorBoundary>
                </div>
              </UserContext.Provider>
            </Router>
          </AuthProvider>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
