import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function NotActiveWorkshop({ matchedSessions }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`collapse collapse-arrow bg-base-200 ${isOpen ? 'open' : ''}`}>
      <input type="checkbox" checked={isOpen} onChange={handleToggle} />
      <div
        onClick={handleToggle}
        className="collapse-title text-xl font-medium">
        نمایش دوره های غیر فعال
      </div>
      <div className="collapse-content">
        <div className="my-5 grid md:grid-cols-2 gap-4">
          {matchedSessions
            ?.filter((item) => item.active === false)
            .map((item) => (
              <div
                key={item.name}
                className="cursor-grab flex flex-col items-center justify-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
            ">
                <div className="card-body ">
                  <h2 className="card-title"> {item.name}</h2>
                  <div className="badge badge-ghost mx-auto">
                    این دوره به پایان رسیده است
                  </div>
                  <div
                    className="card-actions justify-end
            ">
                    <div className="grid md:grid-cols-1 gap-3 items-center px-3 py-2 text-sm font-medium text-center text-main rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                      <Link
                        className="bg-blue-500 w-full hover:bg-gray-700 text-white font-bold py-2 px-4 border border-gray-700 rounded"
                        to={`/dashboard/workshop`}
                        state={{ data: item.name }}>
                        دسترسی به جلسات آفلاین
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default NotActiveWorkshop;
