import { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';
import api from '../../../api/api';
import FilePreview from '../../../component/FilePreview';
import moment from 'moment-jalaali';

const ReportHistory = () => {
  const [reports, setReports] = useState([]);
  const [userId, setUserId] = useState('');
  console.log('reports', reports);
  const [isWeeklyLoading, setIsWeeklyLoading] = useState(false);
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      setUserId(parsedCredentials.userId);
    }
  }, []);
  const getReports = async () => {
    if (userId) {
      try {
        const res = await api.get(`/report/report/${userId}`);

        setReports(res.data);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getReports();
  }, [userId]);

  const handleDeleteWeeklyReport = (item) => {
    console.log(item);
    setIsWeeklyLoading(true);
    api
      .put(`/report/${item}`, {
        archive: true,
      })
      .then((res) => {
        console.log(res.data);
        setIsWeeklyLoading(false);
        getReports();
        alert('Report deleted successfully');
      })
      .catch((err) => {
        console.log(err);
        setIsWeeklyLoading(false);
        // setIsWeeklyError(true);
        // setErrorMsg(err.message);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center md:w-full sm:w-1/2 h-full text-main">
      {reports.map((report) => {
        return (
          report.archive !== true && (
            <div
              key={report.id}
              className="flex flex-col bg-white rounded-lg shadow-lg md:w-2/4 sm:w-4/5 border  m-4">
              <div className="flex justify-between items-center p-4">
                <div className="text-white bg-main p-3 rounded font-custom1">
                  تاریخ ارسال گزارش:
                  {moment(report?.date).format('jYYYY/jM/jD HH:mm')}
                </div>
              </div>
              <div className="flex flex-wrap p-4">
                {report?.data.map((item, index) => {
                  return (
                    <div key={index} className="m-2">
                      <FilePreview fileUrl={item.url} index={index} />
                      <div>{item.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="mx-5 mb-5">{report.description}</div>
            </div>
          )
        );
      })}
      {
        //show proper msg if there is no report
        //check if reports with active === true exist
        reports.filter((report) => report.archive !== true).length === 0 && (
          <div className="m-4 text-center bg-gray-200 p-4 rounded-lg shadow-md w-3/4">
            گزارشی برای نمایش وجود ندارد
          </div>
        )
      }
    </div>
  );
};

export default ReportHistory;
