import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import api from '../../../api/api.js';
import FileInputs from '../../../component/FormInputs/FileInputs.js';
import { Alert, Textarea } from 'flowbite-react';
import SubmitButton from '../../../component/submitButton.jsx';
import persianNumber from '../../../api/persianNumber.js';
import persianNumbers from '../../../api/persianNumber.js';
import ReportHistory from './history.jsx';
const numberOfDays = [0, 1, 2, 3, 4, 5, 6, 7];

const WeeklyReport = () => {
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState('');
  const [aveWeight, setAveWeight] = useState(0);
  const [userId, setUserId] = useState();
  const [description, setDescription] = useState('');
  const [daysOfStrength, setDaysOfStrength] = useState('');
  const [daysOfCardio, setDaysOfCardio] = useState('');
  const [status, setStatus] = useState('idle');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  console.log('percent', percent);
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      try {
        const parsedCredentials = JSON.parse(credentials);
        console.log(parsedCredentials.userId);
        setUserId(parsedCredentials.userId);
      } catch (error) {
        console.log(error);
        setMessage(error.message);
      }
    }
  }, []);

  const handleUploadImages = async (e) => {
    console.log('inside handle upload files');
    const selectedFiles = Array.from(e.target.files); // Convert FileList to array
    setFiles(selectedFiles); // Update state with the selected files
    await uploadFiles(selectedFiles); // Automatically upload images after selection
  };

  const uploadFiles = async (selectedFiles) => {
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append('images', file); // 'images' should match the key the backend expects
    });

    try {
      setStatus('uploading');
      console.log('upload files');
      const response = await api.post(
        '/imageUploader/multiple', // Update with your Express server endpoint
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total);
            setPercent(percentage);
          },
        }
      );
      setImageUrls(response.data.urls); // Assuming response.data.urls is an array of image URLs
      setButtonDisabled(false);
      setPercent(100);
      setStatus('images_uploaded');
    } catch (error) {
      console.log(error.response.data);
      setMessage(error.response.data);
      console.error('Error uploading files', error);
      setStatus('error');
      Bugsnag.notify('Error uploading images', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      userId,
      reportType: 'weekly',
      description,
      daysOfStrength,
      daysOfCardio,
      aveWeight,
      images: imageUrls, // URLs from uploaded images
    };

    setStatus('loading');

    try {
      const response = await api.post('/report/upload', requestData);

      //window.location.href = '/dashboard/weekly-history';
      setStatus('success');
      alert('گزارش شما با موفقیت ثبت شد');
      window.location.reload();
    } catch (error) {
      console.error(error);
      setMessage('خطا در ارسال گزارش');
      setStatus('error');
      Bugsnag.notify('Error in submitting report');
    }
  };

  return (
    <div className="mx-4 font-custom1 flex flex-col mt-5 items-center">
      <div className="flex flex-row  mb-5">
        {status === 'loading' && (
          <div>
            <iframe src="https://lottie.host/embed/95be7968-7651-4619-96f0-1bbe26a2bba7/jkpnuPrKGq.json"></iframe>
          </div>
        )}
        {status === 'error' && (
          <div>
            <Alert color="failure">
              <span className="font-medium">خطا</span>
              <p>{message}</p>
            </Alert>
          </div>
        )}
        {status === 'uploading' && (
          <div>
            <Alert color="info">
              <span className="font-medium">در حال آپلود تصاویر</span>
              <span>%{persianNumbers(percent)}</span>
            </Alert>
          </div>
        )}
        {status === 'images_uploaded' && (
          <div>
            <Alert color="success">
              <span className="font-medium">تصاویر شما آپلود شد</span>
              <p>
                حالا میتوانید بعد از درج توضیحات، نسبت به ارسال گزارش اقدام کنید
              </p>
            </Alert>
          </div>
        )}
      </div>
      <form
        className="rounded-lg items-center w-full max-w-2xl"
        onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-1 gap-5 items-center justify-items-center">
          <div className="w-full flex flex-col items-center">
            <FileInputs
              onChange={handleUploadImages}
              id="images"
              name="images"
              helpText="تمامی تصاویر و فایلهای مورد نظر خود را انتخاب کنید.


              "
              multiple={true} // Allow multiple file uploads
            />
          </div>
          <select
            className="select select-bordered w-full max-w-xs"
            onChange={(e) => setDaysOfStrength(e.target.value)}>
            <option value="">تعداد روزهای تمرین قدرتی</option>
            {numberOfDays.map((day) => (
              <option value={`${day}`} key={day}>
                روز {day}
              </option>
            ))}
          </select>
          <br />
          <select
            className="select select-bordered w-full max-w-xs"
            onChange={(e) => setDaysOfCardio(e.target.value)}>
            <option value="">تعداد روزهای کاردیو</option>
            {numberOfDays.map((day) => (
              <option value={`${day}`} key={day}>
                روز {persianNumber(day)}
              </option>
            ))}
          </select>
          <br />
          <input
            type="number"
            placeholder="میانگین وزن هفتگی"
            className="input input-bordered w-full max-w-xs"
            onChange={(e) => setAveWeight(e.target.value)}
          />
        </div>
        <Textarea
          className=" mt-4 w-1/2  mx-auto mb-5
          "
          onChange={(e) => setDescription(e.target.value)}
          id="comment"
          placeholder="توضیحات"
          required
          rows={4}
        />

        <>
          {status === 'loading' && (
            <div className="weekly-report__progress">
              <p className="text-main">در حال ذخیره...</p>
              <progress
                className="weekly-report__progress-bar"
                value={percent}
                max="100"
              />
            </div>
          )}

          {!buttonDisabled && (
            <div className="flex justify-center">
              <SubmitButton
                handleSubmit={handleSubmit}
                status={status}
                disabled={status === 'loading'}
              />
            </div>
          )}
        </>
      </form>
      <div className="mt-10">
        <h1 className="text-2xl font-bold">گزارشات هفتگی قبلی</h1>
      </div>
      <ReportHistory />
    </div>
  );
};

export default WeeklyReport;
